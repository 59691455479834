export default {
  colors: {
    primary: '#001560',
    secondary: '#5FE90A',
    accent: '#E90A3D',
    fadePrimary: '#0F2572',
    fadePrimary2: '#0D276B',
    lightGreen: '#B2DF8A',
    darkGreen: '#42950F',
    lightPink: '#FB9A99',
    blue: '#45B8F5',
    lightBlue: '#A6CEE3',
    lightGray: '#BCBCBC',
    mediumGray: '#E9E9E9',
    midGray: '#8F8F8F',
    darkGray: '#808081',
    black: '#1C1C1C',
    cardBorder: 'rgba(0, 0, 0, 0.1)',
    itemBorder: '#EDEDED',
  },
};
